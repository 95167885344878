*{
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media(max-width: 840px){
  .container{
    display: none;
  }
}
.MuiTab-iconWrapper{
  background-color: rgba(200, 200, 207, 0.527);
}
.horizontalNav{
  display: flex;
  align-items: center;
}
.breaker{
  width: 1px;
  height: 100%;
  background-color: #000;
}

.styleBoxDiv{
  width: 800px;
  display: flex;
  justify-content: space-between;
}
.compareButton{
  width: 300px;
  z-index: 99;
  
}
.map{
  height: 400px;
  width: 400px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}